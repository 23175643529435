<template>
    <div>
        <v-btn style="margin: 20px;" color="success" :disabled="createLoading" :loading="createLoading" @click="createTemplate"><v-icon>add</v-icon> {{ $t('erp.lang_newTemplate') }} </v-btn>

        <v-data-table
                :loading="loading"
                :headers="datatableHeaders"
                :items="filteredTemplates"
                class="elevation-1"
        >
            <template v-slot:item="{item}">
                <tr>
                    <td>{{ item.id }}</td>
                    <td style="width: 200px;">{{ item.count }}</td>
                    <td style="width: 300px;">
                        <v-chip v-if="item.tags.slideshow === 1" small label color="info">Slideshow</v-chip>
                        <v-chip v-if="item.tags.youtube === 1" small label color="info">YoutubePlayer</v-chip>
                    </td>
                    <td style="width: 110px;">
                        <v-chip v-if="item.status === 1" small label color="success"> {{ $t('settings.lang_settings_activ') }} </v-chip>
                        <v-chip v-if="item.status === 0" small label color="error"> {{ $t('generic.lang_inactive') }} </v-chip>
                    </td>
                    <td style="text-align: right;">
                        <v-btn @click="editTemplate(item)" icon text color="primary">
                            <v-icon>edit</v-icon>
                        </v-btn>
                        <v-btn :disabled="item.count > 0" @click="deleteTemplate = item" icon text color="error">
                            <v-icon>delete</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </template>
        </v-data-table>

        <v-dialog persistent max-width="400" :value="deleteTemplate !== null">
            <v-card>
                <v-card-title> {{ $t('settings.lang_deleteTemplate') }} </v-card-title>
                <v-card-text class="text-center">
                    {{ $t('generic.lang_actionCannotbeUndone') }}
                </v-card-text>
                <v-card-actions>
                    <v-btn color="error" text :disabled="loading" @click="deleteTemplate = null"> {{ $t('generic.lang_cancel') }} </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" :disabled="loading" :loading="loading" @click="deleteCustomerDisplayTemplate(deleteTemplate)"> {{ $t('generic.lang_delete') }} </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {ENDPOINTS} from "../../../config";
    import mixin from "../../../mixins/KeyboardMixIns";
    import {mapState} from "vuex";

    export default {
        name: "Templates",

        mixins: [mixin],

        data() {
            return {
                ENDPOINTS,
                loading: false,
                createLoading: false,
                // --- Datatable ---
                templates: {},
                deleteTemplate: null
            }
        },

        mounted() {
            this.getTemplates();
        },

        computed: {
            ...mapState([
                'api'
            ]),
            datatableHeaders() {
                return [
                    {
                        text: 'ID',
                        align: 'left',
                        value: 'id',
                        width: 80,
                        hide:true
                    },
                    { text: this.$t('generic.lang_inUse'), value: 'count' },
                    { text: '', value: 'tags' },
                    { text: '', value: 'status', align: 'right' },
                    { text: '', value: '' },
                ];
            },
            filteredTemplates() {
                let templates = [];

                for(let template in this.templates) {
                    if(!this.templates.hasOwnProperty(template)) {
                        continue;
                    }

                    template = this.templates[template];
                    templates.push({
                        id: template.id,
                        count: template.count,
                        status: template.status,
                        tags: {
                            youtube: template.youtubePlayer,
                            slideshow: template.slideshow
                        }
                    });
                }

                return templates;
            }
        },

        methods: {
            getTemplates() {
                this.loading = true;

                this.axios.post(ENDPOINTS.SETTINGS.CUSTOMERDISPLAYS.TEMPLATES.GET).then((res) => {
                    if(res.data.success) {
                        this.templates = res.data.data;
                    }

                    this.loading = false;
                }).catch(() => {
                    this.loading = false;
                });
            },
            deleteCustomerDisplayTemplate(template) {
                this.loading = true;

                this.axios.post(ENDPOINTS.SETTINGS.CUSTOMERDISPLAYS.TEMPLATE.DELETE, {
                    id: template.id
                }).then((res) => {
                    if(res.data.success) {
                        this.deleteTemplate = null;
                        this.getTemplates();
                    }

                    this.loading = false;
                }).catch(() => {
                    this.loading = false;
                });
            },
            editTemplate(template) {
                this.$router.push({
                    name: "settings.cashierSettings.customerDisplayTemplate",
                    params: {
                        id: template.id
                    }
                });
            },
            createTemplate() {
                this.createLoading = true;

                this.axios.post(ENDPOINTS.SETTINGS.CUSTOMERDISPLAYS.TEMPLATE.CREATE).then((res) => {
                    if(res.data.success) {
                        this.$router.push({
                            name: "settings.cashierSettings.customerDisplayTemplate",
                            params: {
                                id: res.data.templateID
                            }
                        });
                    }
                    else {
                        this.createLoading = false;
                    }
                }).catch(() => {
                    this.createLoading = false;
                })
            }
        }
    }
</script>