<template>
    <div>
        <page-title :heading="$t('settings.lang_customerDisplays') " :subheading="$t('settings.lang_customerDisplays')" :icon=icon></page-title>

        <div class="app-main__inner">
            <v-card flat tile>
                <v-tabs v-model="tab" background-color="transparent">
                    <v-tab key="list">
                        {{ $t('settings.lang_customerDisplays') }}
                    </v-tab>

                    <v-tab key="templates">
                        {{ $t('settings.lang_customerDisplaysTemplates') }}
                    </v-tab>

                    <v-tabs-items touchless v-model="tab">
                        <v-tab-item key="list">
                            <CustomerDisplays ref="customerdisplays"></CustomerDisplays>
                        </v-tab-item>

                        <v-tab-item key="templates">
                            <Templates ref="templatesRef"></Templates>
                        </v-tab-item>
                    </v-tabs-items>
                </v-tabs>
            </v-card>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../Layout/Components/PageTitle.vue";
    import CustomerDisplays from "../../components/settings/customerdisplay/CustomerDisplays";
    import Templates from "../../components/settings/customerdisplay/Templates";

    export default {
        name: "Customerdisplay",

        components: {
            Templates,
            CustomerDisplays,
            PageTitle,
        },

        data: () => ({
            icon: 'pe-7s-monitor icon-gradient bg-tempting-azure',
            tab: 0
        }),

        watch: {
            tab(val) {
                if(val === 0) {
                    this.$refs?.customerdisplays?.getTemplates();
                }

                if(val === 1) {
                    this.$refs?.templatesRef?.getTemplates();
                }
            }
        }
    }
</script>