<template>
    <v-dialog :value="showDialog" max-width="700">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title> {{ $t('settings.lang_newCustomerDisplay') }} </v-toolbar-title>
            </v-toolbar>

            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <v-select outlined
                                  :items="templates"
                                  v-model="template"
                                  item-value="id"
                                  item-text="text"
                                  :label="$t('generic.lang_template')"
                        ></v-select>
                    </v-col>

                    <v-col cols="6">
                        <v-select outlined
                                  :items="filteredCashierIDs"
                                  :loading="loading"
                                  :disabled="loading"
                                  v-model="cashierID"
                                  item-value="id"
                                  item-text="text"
                                  :label="$t('generic.lang_cashierID')"
                        ></v-select>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-btn @click="closeDialog" text color="error"> {{ $t('generic.lang_cancel') }} </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="success" :loading="loading" :disabled="loading || cashierID === 0 || template === null" @click="createCustomerDisplay">{{$t('generic.lang_add')}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {ENDPOINTS} from "../../../config";

    export default {
        name: "CustomerDisplayCreateDialog",

        props: {
            templates: Array,
            showDialog: Boolean
        },

        data() {
            return {
                loading: false,
                cashierIDs: [],
                template: null,
                cashierID: 0
            }
        },

        computed: {
            filteredCashierIDs() {
                return this.cashierIDs.map((cashierID) => {
                    return {
                        id: cashierID[0],
                        text: this.$t('generic.lang_kasse')+" "+cashierID
                    };
                });
            }
        },

        watch: {
            showDialog(val) {
                if(!val) {
                    this.cashierID = 0;
                    this.template = null;
                }
                else {
                    this.getCashierIDs();
                }
            }
        },

        mounted() {
            this.getCashierIDs();
        },

        methods: {
            closeDialog() {
                this.$emit("closeDialog");
            },
            getCashierIDs() {
                this.loading = true;

                this.axios.post(ENDPOINTS.GENERIC.CASHIERIDS.GET).then((res) => {
                    this.loading = false;
                    this.cashierIDs = res.data.cashierIDs;
                }).catch(() => {
                    this.loading = false;
                });
            },
            createCustomerDisplay() {
                this.loading = true;

                this.axios.post(ENDPOINTS.SETTINGS.CUSTOMERDISPLAY.CREATE, {
                    template: this.template,
                    cashierID: this.cashierID,
                }).then((res) => {
                    this.loading = false;

                    if(res.data.success) {
                        this.$emit("created");
                    }
                }).catch(() => {
                    this.loading = false;
                });
            },
        }
    }
</script>