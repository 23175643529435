<template>
    <div>
        <v-btn style="margin: 20px;" color="success" @click="showCreateDialog = true"><v-icon>add</v-icon> {{ $t('settings.lang_newDisplay') }} </v-btn>

        <v-data-table
                :loading="loading"
                :headers="datatableHeaders"
                :items="customerdisplays"
                class="elevation-1"
        >
            <template v-slot:item="{item}">
               <tr>
                   <td>{{ item.id }}</td>
                   <td style="width: 250px;">
                       <v-select
                                 outlined
                                 style="margin-top: 20px;"
                                 :items="filteredTemplates"
                                 v-model="item.template"
                                 item-value="id"
                                 item-text="text"
                                 @change="changeDisplayTemplate(item)"
                       ></v-select>
                   </td>
                   <td style="width: 110px;">{{ $t('generic.lang_kasse') }} {{ item.cashierID }}</td>
                   <td style="width: 110px;">
                       <v-chip v-if="item.status === 1" small label color="success"> {{ $t('settings.lang_settings_activ') }} </v-chip>
                       <v-chip v-if="item.status === 0" small label color="error"> {{ $t('generic.lang_inactive') }} </v-chip>
                   </td>
                   <td style="text-align: right;">
                       <v-btn @click="openCustomerDisplay(item)" color="primary" icon text>
                           <v-icon>open_in_new</v-icon>
                       </v-btn>

                       <v-btn @click="deleteDisplay = item" icon text color="error">
                           <v-icon>delete</v-icon>
                       </v-btn>
                   </td>
               </tr>
            </template>
        </v-data-table>

        <CustomerDisplayCreateDialog :show-dialog="showCreateDialog" @closeDialog="showCreateDialog = false" @created="customerDisplayCreated" :templates="filteredTemplates"></CustomerDisplayCreateDialog>

        <v-dialog persistent max-width="400" :value="deleteDisplay !== null">
            <v-card>
                <v-card-title>  {{ $t('settings.lang_cust_display_delete_confirm') }} </v-card-title>
                <v-card-text class="text-center">
                    {{ $t('generic.lang_actionCannotbeUndone') }} 
                </v-card-text>
                <v-card-actions>
                    <v-btn color="error" text :disabled="loading" @click="deleteDisplay = null"> {{ $t('generic.lang_cancel') }} </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" :disabled="loading" :loading="loading" @click="deleteCustomerdisplay(deleteDisplay)"> {{ $t('generic.lang_delete') }} </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {ENDPOINTS} from "../../../config";
    import mixin from "../../../mixins/KeyboardMixIns";
    import {mapState} from "vuex";

    import CustomerDisplayCreateDialog from "./CustomerDisplayCreateDialog";

    export default {
        name: "CustomerDisplays",

        components: {CustomerDisplayCreateDialog},

        mixins: [mixin],

        data() {
            return {
                ENDPOINTS,
                loading: false,
                // --- Datatable ---
                template: null,
                customerdisplays: [],
                templates: {},
                showCreateDialog: false,
                deleteDisplay: null
            }
        },

        mounted() {
            this.getTemplates();
        },

        computed: {
            ...mapState([
                'api'
            ]),
            datatableHeaders() {
                return [
                    {
                        text: 'ID',
                        align: 'left',
                        value: 'id',
                        width: 80,
                        hide:true
                    },
                    { text: this.$t('generic.lang_template') , value: 'template' },
                    { text: this.$t('generic.lang_kasse') , value: 'cashierID' },
                    { text: '', value: 'status', align: 'right' },
                    { text: '', value: '' },
                ];
            },
            filteredTemplates() {
                let templates = [];

                for(let template in this.templates) {
                    if(!this.templates.hasOwnProperty(template)) {
                        continue;
                    }

                    template = this.templates[template];

                    templates.push({
                        id: template.id,
                        text: this.$t('generic.lang_template')+" "+template.id
                    });
                }

                return templates;
            }
        },

        methods: {
            getCustomerDisplays() {
                this.loading = true;

                this.axios.post(ENDPOINTS.SETTINGS.CUSTOMERDISPLAYS.GET).then((res) => {
                    this.loading = false;

                    if(res.data.success) {
                        this.customerdisplays = res.data.data;
                    }
                }).catch(() => {
                    this.loading = false;
                });
            },
            getTemplates() {
                this.loading = true;

                this.axios.post(ENDPOINTS.SETTINGS.CUSTOMERDISPLAYS.TEMPLATES.GET).then((res) => {
                    if(res.data.success) {
                        this.templates = res.data.data;

                        this.getCustomerDisplays();
                    }
                    else {
                        this.loading = false;
                    }
                }).catch(() => {
                    this.loading = false;
                });
            },
            changeDisplayTemplate(display) {
                this.axios.post(ENDPOINTS.SETTINGS.CUSTOMERDISPLAY.TEMPLATE.UPDATE, {
                    display: display.id,
                    template: display.template
                }).then((res) => {
                    if(!res.data.success) {
                        this.$socket.emit("customerdisplay.templateUpdated");

                        this.getCustomerDisplays();
                    }
                }).catch(() => {
                    this.getCustomerDisplays();
                });
            },
            deleteCustomerdisplay(display) {
                this.loading = true;

                this.axios.post(ENDPOINTS.SETTINGS.CUSTOMERDISPLAY.DELETE, {
                    id: display.id
                }).then((res) => {
                    this.loading = false;

                    if(res.data.success) {
                        this.deleteDisplay = null;

                        this.getCustomerDisplays();
                    }
                }).catch(() => {
                    this.loading = false;

                    this.getCustomerDisplays();
                });
            },
            openCustomerDisplay(display) {
              let customerDisplayURL = process.env.VUE_APP_CUSTOMERDISPLAY_URL
              if(process.env.VUE_APP_HYBRID == "true") {
                if (window.location.hostname != 'localhost' && window.location.hostname != 'devkasse.3pos.de' && window.location.hostname != 'kasse.3pos.de') {
                  customerDisplayURL = location.protocol + "//" + window.location.hostname + ":8035";
                }
              }

                window.open(customerDisplayURL+'?displayID='+display.id+'&cashierID='+display.cashierID+'&bs='+this.api.auth.posID+'&bsid='+this.api.auth.accessString, '_blank');
            },
            customerDisplayCreated() {
                this.showCreateDialog = false;
                this.getCustomerDisplays();
            }
        }
    }
</script>